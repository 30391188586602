.chordinatr-header {
  background-color: $gray-800;
  color: $white;
  position: relative;
  .app-title {
    position: static;
    padding-right: $settings-toggle-width;
  }
  .btn {
    border-radius: 0;
    min-height: 100%;
    vertical-align: bottom;
  }
  h1 {
    font-size: 2em;
    text-align: center;
  }
  .settings-toggle {
    position: absolute;
    right: 0;
    top: 0;
    width: $settings-toggle-width;
    z-index: 99;
  }
  .tour-toggle {
    position: absolute;
    right: $settings-toggle-width;
    top: 0;
    width: $settings-toggle-width;
    z-index: 99;
  }
  .tools {
    justify-content: center;
    padding-right: $settings-toggle-width * 2;
    .dropdown {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}
