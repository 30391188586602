body {
  color: $gray-700;
}

.btn:disabled {
  cursor: not-allowed;
}

.highlightable {
  transition: box-shadow 0.8s;
}

.rotate-90 {
  transform: rotate(90deg);
}

.cursor-pointer {
  cursor: pointer;
}

.text-purple {
  color: $purple !important;
}
.text-green {
  color: $green !important;
}
.text-blue {
  color: $blue !important;
}
.text-orange {
  color: $orange !important;
}
.text-yellow {
  color: $yellow !important;
}
.text-red {
  color: $red !important;
}
