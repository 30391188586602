.chordinatr-header {
  h1 {
    font-size: 2rem;
  }
  .tools {
    .btn {
      font-size: 1rem;
    }
  }
}
