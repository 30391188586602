$table-label-width: $grid-gutter-width;
$chord-info-window-height: 200px;

#page-wrap {
  padding-bottom: $chord-info-window-height;
  &.box-in {
    padding-bottom: 0;
  }
}

.chords {
  width: 100%;
  sup {
    display: block;
    line-height: normal;
    top: auto;
  }
  .chords-table {
    flex-flow: nowrap;
    margin-bottom: $grid-gutter-width / 2;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    .chords-header-table {
      display: none;
      .btn {
        padding: 0;
        pointer-events: none;
      }
    }
    .chord-collection-wrap {
      .chord-collection-label {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
        &:disabled {
          cursor: not-allowed;
        }
      }
      .chord-collection {
        border: 2px solid $info;
        border-bottom-color: darken($info, 10%);
        border-bottom-width: 3px;
        border-top-width: 0;
        margin-bottom: 5px;
        max-height: 3px;
        overflow-y: hidden;
        transition: max-height 0.2s ease-in-out, border-bottom-color 0.2s;
        &.in {
          border-bottom-color: $info;
          max-height: 600px;
        }
      }

      .table {
        height: 100%;
        margin-bottom: 0;
        position: relative;
        table-layout: fixed;
        width: 100%;
        th,
        td {
          vertical-align: middle;
          width: auto;
          .tooltip-wrap {
            color: $info;
            cursor: pointer;
            display: none;
            margin-left: 10px;
          }
        }
        thead {
          th:first-child {
            width: 50px;
          }
          th {
            padding: $table-cell-padding-xs;
          }
        }
        tbody {
          th {
            border-right: 2px solid $gray-400;
            padding: 0;
            text-align: center;
            .scale-degree {
              display: inline;
              padding: $table-cell-padding-xs;
              white-space: nowrap;
            }
          }
          td {
            height: 100%;
            padding: 0;
            > .chord-single {
              border: 0;
              color: inherit;
              display: block;
              height: 100%;
              padding: $table-cell-padding-xs;
              width: 100%;
              &:hover,
              &:focus,
              &:active {
                text-decoration: none;
              }

              &.faded {
                opacity: 0.3;
              }

              &.inversion-added-2 {
                box-shadow: inset 0 0 50px -5px rgba($blue, 0.3);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $blue;
                }
              }
              &.inversion-added-1 {
                box-shadow: inset 0 0 50px -5px rgba($blue, 1);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $blue;
                }
              }

              &.inversion-removed-2 {
                box-shadow: inset 0 0 50px -5px rgba($orange, 0.3);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $orange;
                }
              }
              &.inversion-removed-1 {
                box-shadow: inset 0 0 50px -5px rgba($orange, 1);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $orange;
                }
              }

              &.inversion-changed-2 {
                box-shadow: inset 0 0 50px -5px rgba($yellow, 0.3);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $yellow;
                }
              }
              &.inversion-changed-1 {
                box-shadow: inset 0 0 50px -5px rgba($yellow, 1);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $yellow;
                }
              }

              &.inversion-common-2 {
                box-shadow: inset 0 0 50px -5px rgba($purple, 0.3);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $purple;
                }
              }
              &.inversion-common-3 {
                box-shadow: inset 0 0 50px -5px rgba($purple, 1);
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $purple;
                }
              }

              &.inversion-0 {
                box-shadow: inset 0 0 50px -5px $green;
                &.active-chord-middle {
                  box-shadow: 0 0 50px -5px $green;
                }
              }

              &.active-chord-left {
                box-shadow: inset 0 0 50px -5px $red;
              }
              &.active-chord-middle {
                box-shadow: 0 0 50px -5px $red;
              }
              &.active-chord-right {
                box-shadow: inset 0 0 50px -5px $red;
              }
            }
          }
        }
      }
    }
  }
}

.chord-info {
  bottom: 0;
  display: none;
  // height: $chord-info-window-height;
  opacity: 0;
  pointer-events: none;
  position: sticky;
  transition: opacity 0.2s;
  width: 100%;
  &.fade-enter,
  &.fade-enter-active,
  &.fade-enter-done,
  &.fade-exit,
  &.fade-exit-active {
    display: block;
  }

  &.fade-enter-done,
  &.fade-enter-active {
    opacity: 1;
  }

  .chord-info-inner {
    height: 100%;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s;
    &.is-intersecting {
      opacity: 0.5;
      pointer-events: none;
      &.has-sticky {
        opacity: 1;
        pointer-events: auto;
      }
    }
    > .row {
      align-items: stretch;
      background-color: $gray-100;
      border-bottom: 2px solid $gray-200;
      box-shadow: 0 -10px 15px -15px $black;
      height: 100%;

      .col {
        border-left: 2px solid $gray-200;
        border-right: 2px solid $gray-200;
        // padding-: gutter();

        &:first-child {
          border: 0;
        }
        &:last-child {
          border: 0;
        }
      }
    }
  }
  .info-box {
    align-items: center;
    border-bottom: 1px solid $gray-400;
    font-size: 1.75rem;
    padding: gutter(4) 0;
    .clear {
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      z-index: 999;
    }
    .notes {
      margin-bottom: 0;
      td {
        border: 0;
      }
      .note {
        display: inline-block;
      }
    }
    .actions {
      .col {
        padding-left: 5px;
        padding-right: 5px;
        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
      .btn {
        margin-bottom: 5px;
        text-align: center;
        width: 100%;
        &.clear {
          width: auto;
        }
      }
    }
  }
}
