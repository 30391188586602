.chords {
  sup {
    display: inline;
    line-height: normal;
    top: -0.5em;
  }
  .chords-table {
    width: auto;
    .chords-header-table {
      display: inline-table;
      float: left;
      margin-top: 2px;
      width: auto;
      thead {
        tr {
          th {
            border-top: 1px solid transparent;
          }
        }
      }
    }
    .chord-collection-wrap {
      display: inline-block;
      margin-right: 1px;
      padding-right: $table-label-width - 1px;
      position: relative;
      .chord-collection-label {
        border: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
        bottom: 0;
        box-shadow: inset -3px 0 10px -3px $white;
        cursor: pointer;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: $table-label-width;
        > div {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
      .table {
        table-layout: auto;
        thead {
          span > sup {
            top: -0.5em;
          }
          th {
            padding: $table-cell-padding;
            width: 75px;
            &:first-child {
              display: none;
            }
          }
        }
        tbody {
          th {
            display: none;
            text-align: left;
            .scale-degree {
              padding: $table-cell-padding;
            }
          }
          td {
            > .chord-single {
              padding: $table-cell-padding;
            }
          }
        }
      }
      .chord-collection {
        border-bottom-color: $info;
        border-bottom-width: 2px;
        border-left-color: darken($info, 10%);
        border-left-width: 3px;
        border-right-width: 0;
        border-top-width: 2px;
        margin-bottom: 0;
        max-height: none;
        max-width: 3px;
        overflow-x: hidden;
        overflow-y: auto;
        transition: max-width 0.2s ease-in-out, border-left-color 0.2s;
        &.in {
          border-left-color: $info;
          max-height: none;
          max-width: 600px;
        }
      }
    }
  }
}

.table {
  tbody {
    th {
      text-align: left;
    }
  }
}

.chord-info {
  font-size: 1.75rem;
  .actions {
    .btn {
      margin-bottom: 0;
      text-align: left;
      width: auto;
    }
  }
  .chord-info-inner {
    > .row {
      padding: gutter();
    }
  }
  .info-box {
    border-bottom: 0;
  }
  .table {
    margin: 0 auto gutter();
    width: auto;
    .notes {
      .note {
        margin-right: 5px;
      }
    }
    td {
      border: 0;
    }
  }
}
