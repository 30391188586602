.chordinatr-header {
  h1 {
    text-align: left;
    margin: 0.3rem 0;
    font-size: 1.5rem;
  }
  .tools {
    justify-content: flex-end;
    height: 100%;
    .dropdown {
      width: auto;
    }
    .btn {
      font-size: 0.8rem;
      width: auto;
    }
  }
}
