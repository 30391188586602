.degrees {
  .table {
    table-layout: fixed;
    th,
    td {
      &:first-child {
        padding: 0 0.75em;
        text-align: left;
        width: 175px;
      }
    }
    thead {
      th {
        .btn {
          width: 90%;
          span {
            writing-mode: horizontal-tb;
          }
          .note {
            display: block;
            margin-left: 0 !important;
          }
        }
      }
      .actions {
        padding: $table-cell-padding;
        .btn {
          width: 115px;
          &:first-child {
            margin-bottom: 0.25rem;
          }
        }
      }
    }
    tbody {
      th {
        .btn-group-vertical {
          align-items: flex-start;
          flex-direction: row;
          justify-content: center;
          .degree-control {
            border-radius: 0.2rem;
            margin: 0;
            &:first-child {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
            &:last-child {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-left: 0;
            }
          }
          .degree-single {
            padding: $table-cell-padding;
          }
        }
        .degree-control {
          display: inline;
          padding: 0.5rem 0.75rem;
          width: auto;
        }
      }
      td {
        .btn {
          padding: $table-cell-padding;
        }
      }

      .degrees-cell-inner {
        .scale-degree {
          display: inline;
          white-space: nowrap;
        }
      }
    }
  }
}
