.degrees {
  margin-bottom: gutter();
  .table {
    border-bottom: 0;
    margin-bottom: 0;
    table-layout: fixed;
    th,
    td {
      border-width: 0;
      vertical-align: middle;
      width: auto;
      &:first-child {
        width: 95px;
      }
    }

    thead {
      th {
        padding: 0;
        .btn {
          border: 2px solid transparent;
          padding: 0.5em 0;
          &.btn-default {
            &:hover {
              border-color: $success;
            }
          }
          span {
            writing-mode: vertical-lr;
          }
          .note {
            display: inline;
          }
        }
      }
      .actions {
        padding: $table-cell-padding-xs;
        .btn {
          font-size: $input-btn-font-size-sm;
          line-height: $input-btn-line-height-sm;
          margin: 0;
          padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
          &:first-child {
            margin-right: 5%;
          }
        }
      }
    }

    tbody {
      th {
        border-right: 2px solid $gray-400;
        line-height: 1.5;
        padding: 0;
        text-align: left;
        .btn-group-vertical {
          .degree-control {
            &:active {
              background-color: transparent;
              color: $primary;
            }
            &:disabled {
              visibility: hidden;
            }
          }
        }
        .degree-single {
          padding: $table-cell-padding-xs $table-cell-padding;
        }
      }

      td {
        padding: 0;
        .btn {
          padding: $table-cell-padding-xs;
        }
      }
      .degrees-cell-inner {
        height: 0;
        overflow-y: hidden;
        padding: 0;
        transition: height 0.2s ease-in-out, padding 0.2s ease-in-out;
      }
    }

    &.in {
      tbody {
        border-bottom: 2px solid $gray-400;
        th {
          padding: 5px;
        }
        th,
        td {
          border-width: 1px;
        }
        .degrees-cell-inner {
          height: 100%;
          padding: 0;
        }
      }
    }
  }

  .btn-expand-degrees {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    line-height: 1;
    padding: 0;
  }
}

.scale-degree {
  display: inline;
  white-space: nowrap;
  &.btn {
    border: 0;
    font-weight: inherit;
    vertical-align: baseline;
  }
}

.highlight-i-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-i, $active-highlight-opacity);
}
.highlight-bii-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-bii, $active-highlight-opacity);
}
.highlight-ii-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-ii, $active-highlight-opacity);
}
.highlight-biii-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-biii, $active-highlight-opacity);
}
.highlight-iii-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-iii, $active-highlight-opacity);
}
.highlight-iv-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-iv, $active-highlight-opacity);
}
.highlight-bv-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-bv, $active-highlight-opacity);
}
.highlight-v-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-v, $active-highlight-opacity);
}
.highlight-bvi-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-bvi, $active-highlight-opacity);
}
.highlight-vi-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-vi, $active-highlight-opacity);
}
.highlight-bvii-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-bvii, $active-highlight-opacity);
}
.highlight-vii-active {
  box-shadow: inset 0 0 50px -5px rgba($highlight-color-vii, $active-highlight-opacity);
}

.highlight-i-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-i, $comparison-highlight-opacity);
  &.highlight-i-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-i, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-i, $comparison-highlight-opacity);
  }
}
.highlight-bii-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-bii, $comparison-highlight-opacity);
  &.highlight-bii-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-bii, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-bii, $comparison-highlight-opacity);
  }
}
.highlight-ii-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-ii, $comparison-highlight-opacity);
  &.highlight-ii-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-ii, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-ii, $comparison-highlight-opacity);
  }
}
.highlight-biii-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-biii, $comparison-highlight-opacity);
  &.highlight-biii-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-biii, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-biii, $comparison-highlight-opacity);
  }
}
.highlight-iii-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-iii, $comparison-highlight-opacity);
  &.highlight-iii-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-iii, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-iii, $comparison-highlight-opacity);
  }
}
.highlight-iv-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-iv, $comparison-highlight-opacity);
  &.highlight-iv-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-iv, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-iv, $comparison-highlight-opacity);
  }
}
.highlight-bv-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-bv, $comparison-highlight-opacity);
  &.highlight-bv-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-bv, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-bv, $comparison-highlight-opacity);
  }
}
.highlight-v-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-v, $comparison-highlight-opacity);
  &.highlight-v-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-v, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-v, $comparison-highlight-opacity);
  }
}
.highlight-bvi-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-bvi, $comparison-highlight-opacity);
  &.highlight-bvi-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-bvi, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-bvi, $comparison-highlight-opacity);
  }
}
.highlight-vi-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-vi, $comparison-highlight-opacity);
  &.highlight-vi-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-vi, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-vi, $comparison-highlight-opacity);
  }
}
.highlight-bvii-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-bvii, $comparison-highlight-opacity);
  &.highlight-bvii-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-bvii, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-bvii, $comparison-highlight-opacity);
  }
}
.highlight-vii-comparison {
  box-shadow: 0 0 50px -5px rgba($highlight-color-vii, $comparison-highlight-opacity);
  &.highlight-vii-active {
    box-shadow: inset 0 0 50px -5px rgba($highlight-color-vii, $active-highlight-opacity),
      0 0 50px -5px rgba($highlight-color-vii, $comparison-highlight-opacity);
  }
}
