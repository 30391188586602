$menu-color: $light;
$menu-background: $dark;
.bm-cross-button {
  height: 24px;
  width: 24px;
  .bm-cross {
    background: $menu-color;
  }
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  .bm-menu {
    background: $menu-background;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    .form-control-plaintext {
      color: inherit;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    .btn-default {
      color: inherit;
    }
  }
  .bm-item-list {
    color: $menu-color;
    padding: 0.8em;
    .bm-item {
      display: inline-block;
    }
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.chordinatr-header {
  .tonic-label {
    margin-bottom: 0;
    margin-right: $grid-gutter-width / 2;
    cursor: pointer;
    svg {
      font-size: 0.5em;
    }
  }
  .settings-tonic {
    width: 50px;
    background: transparent;
    display: inline;
    border: 0;
    color: $light;
    text-align: center;
    cursor: pointer;
    &:focus {
      background-color: $light;
      color: $dark;
    }
  }
}

.settings-wrap {
  &:focus {
    outline: none;
  }
}

.degrees-edit {
  overflowy: hidden;
  .row {
    align-items: baseline;
    .col {
      transition: width 0.2s;
      padding: 5px;
      &:first-child {
        padding-left: $grid-gutter-width / 2;
      }
      &:last-child {
        padding-right: $grid-gutter-width / 2;
      }
    }
    .col-button {
      flex-grow: 1;
      transition: flex-grow 0.2s, font-size 0.2s;
      .btn {
        transition: background-color 0.2s linear 0.1s,
          border-color 0.2s linear 0.1s;
        &:disabled {
          cursor: not-allowed;
        }
      }
      &:hover {
        flex-grow: 3;
        &.disabled {
          flex-grow: 1;
        }
        .btn {
          background-color: lighten($success, 50%);
          border-color: lighten($success, 25%);
          &:disabled {
            background-color: transparent;
            border-color: transparent;
          }
        }
      }
    }
    .col-select {
      flex-grow: 4;
      position: relative;
      .btn {
        opacity: 0;
        position: relative;
        margin-top: 5px;
        transform: translateY(-100%) rotate(0deg);
        transition: background-color 0.1s linear 0s, border-color 0.1s linear 0s,
          margin-top 0.2s linear 0.1s, opacity 0.1s linear 0.1s,
          transform 0.2s linear 0.1s;
        &:disabled {
          display: none;
        }
      }
      &:hover {
        .btn {
          background-color: lighten($danger, 25%);
          transition: background-color 0.1s linear 0.2s,
            border-color 0.1s linear 0.2s, margin-top 0.2s linear 0s,
            opacity 0.1s linear 0.1s, transform 0.2s linear 0s;
          transform: translateY(0) rotate(360deg);
          opacity: 1;
        }
      }
    }
  }
}
