$degrees: bi i bii ii biii iii biv iv bv v bvi vi bvii vii;
$highlight-color-i: $red;
$highlight-color-bii: $yellow;
$highlight-color-ii: $highlight-color-bii;
$highlight-color-biii: $orange;
$highlight-color-iii: $highlight-color-biii;
$highlight-color-iv: $cyan;
$highlight-color-bv: $green;
$highlight-color-v: $highlight-color-bv;
$highlight-color-bvi: $pink;
$highlight-color-vi: $highlight-color-bvi;
$highlight-color-bvii: $indigo;
$highlight-color-vii: $highlight-color-bvii;
$active-highlight-opacity: 1;
$comparison-highlight-opacity: 1;
$settings-toggle-width: 50px;
$table-cell-padding-xs: 0.25rem;
