body,
html {
  width: 100%;
}
.tour-in {
  overflow: hidden;
}
.chordinatr {
  text-align: center;
}
